<template>
  <div class="nodata tc">
    <div>
      <i class="icon-nodata"></i>
    </div>
    <span class="text">{{ txt || "暂无数据" }}</span>
  </div>
</template>
<script>
export default {
  props: ["txt"]
};
</script>

<style lang="less" scoped>
.nodata {
  padding: 0.4rem 0;
}

.icon-nodata {
  display: inline-block;
  width: 2.56rem;
  height: 2.7rem;
  background: url("../../assets/images/img/no-data.png") no-repeat center
    center;
  background-size: 100% auto;
}

// .icon-nosearch {
//   display: inline-block;
//   width: 2.45rem;
//   height: 2.5rem;
//   background: url("../../assets/images/img/empty-icon.png") no-repeat center
//     center;
//   background-size: 100% auto;
// }

.text {
  margin-top: 0.3rem;
  display: block;
  color: #919191;
  font-size: 0.3rem;
}
</style>
